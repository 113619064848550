import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Button, Flex, Input, Select } from "antd";
import style from "../../organisms/doctorConsult/doctorConsult.module.scss"
import { usePaymentLink } from "../../../hooks/usePaymentLink/usePaymentLink";

export const PaymentLink = ({ component }: any) => {
    const { amount, error, number, handleSendPaymentLink, email, handleCollectMobileNumber, handleCollectEmail, handleCatchAmount, handleSelectChange, benList, isLoading ,selectedDetails} = usePaymentLink({ component });
    // console.log('benList:', benList);
    // console.log('selectedDetails:', selectedDetails);
    //eslint-disable-next-line
    return (
        <Container>
            <Flex vertical gap={12}>
                <Input value={amount || ""} allowClear={true} onChange={handleCatchAmount} maxLength={10} placeholder="Please Enter the Amount" />
                <Select
                    mode="tags"
                    size={"middle"}
                    placeholder="Please select"
                    style={{ width: '100%' }}
                    options={benList.map((elem) => ({ label: elem.name + "-" + elem.id+"-"+elem.mobile, value: elem.id }))}
                    onChange={handleSelectChange}
                    maxCount={1}
                    allowClear={true}
                    value={selectedDetails.map((elem:any) => ({ label: elem.name + "-" + elem.id+"-"+elem.mobile, value: elem.id }))}
                >
                </Select>
                <Flex justify="center" >
                    <Text text="or" />
                </Flex>
                <Input value={number || ""} allowClear={true} onChange={handleCollectMobileNumber} maxLength={10} placeholder="Please Enter Mobile" />
                <Input status={error ? 'error' : ''} type="email" value={email || ""} allowClear={true} onChange={handleCollectEmail} placeholder="Please Enter Email" />
                {error && <div style={{ color: 'red' }}>{error}</div>}
                <Button className={style.link_btn} loading={isLoading} onClick={handleSendPaymentLink}>Send Payment Link</Button>
            </Flex>
        </Container>
    );
}