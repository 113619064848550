import { skipToken, useQuery } from "@tanstack/react-query";
import {  useState } from "react";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useNavigate } from "react-router-dom";
import { ShowAlert } from "../../utils/showAlert";
import { errorFormate } from "../../utils/helper";
import { ordersByTicketId, sendAgainPaymentLink } from "../../pages/actions/actions";
// import { useSseTrigger } from "../UseSseTrigger/UseSseTrigger";

export const usePaymentStatus = () => {
    const navigate = useNavigate();
    // const { connectSse } = useSseTrigger()
    const [paymentBtnStatus_Ambulance, setPaymentBtnStatus_Ambulance] = useState<any>({
        isCreated: false,
        isPaid: false,
        isExpired: false,
        isCanceled: false,
        data:null,
    });
    const [paymentBtnStatus_Doctor, setPaymentBtnStatus_Doctor] = useState<any>({
        isCreated: false,
        isPaid: false,
        isExpired: false,
        isCanceled: false,
        data:null
    });
    const { getTicketData } = UseGetStoreData();
    // const {}=useSseTrigger({getTicketData})
    // console.log('getTicketData:', getTicketData);
    const fetchOrders = async (ticketId: string) => {
        try {
            const res = await ordersByTicketId(ticketId)
            return res;
        } catch (e: any) {
            if (e.response.statusCode === 404) {
                return
            } else {
                ShowAlert("warning", errorFormate({ e, service: "getPaymentOrders" }));
            }
        }
    }
    const jumpWithNotification = () => {
        ShowAlert("warning", "Some Technical Issue In retrieving Data");
        navigate("/")
    }
    const shouldFetch = getTicketData?._id ? true : jumpWithNotification();
    const { data: getOrderList } = useQuery({
        queryKey: ["getOrdersByTicketId", getTicketData?._id],
        queryFn: shouldFetch ? () => fetchOrders(getTicketData?._id) : skipToken,
        select: (data) => {
            data.forEach((order: any) => {
                if (order?.notes?.order_by.toLowerCase() === "doctor-consultation") {
                    const res = checkOrderStatus(order);
                    setPaymentBtnStatus_Doctor((prev: any) => ({ ...prev, isCreated: res.hasDisableBtn, isPaid: res.hasPaid, isExpired: res.hasExpired, isCanceled: res.hasCancelled,data:res.payment }))
                } else if (order?.notes?.order_by.toLowerCase() === "ambulance") {
                    const res = checkOrderStatus(order);
                    setPaymentBtnStatus_Ambulance((prev: any) => ({ ...prev, isCreated: res.hasDisableBtn, isPaid: res.hasPaid, isExpired: res.hasExpired, isCanceled: res.hasCancelled,data:res.payment }))
                }
            })
            checkOrderStatus(data)
            return data; // Make sure to return the transformed data if needed
        },
    });
    const checkOrderStatus = (payment: any) => {
        const hasDisableBtn = payment.payment_status !== "paid" && payment.payment_status !== "expired" && payment.payment_status !== "cancelled" && payment.payment_status !== "partially_paid";
        const hasPaid = payment.payment_status === "paid"
        const hasExpired = payment.payment_status === "expired"
        const hasCancelled = payment.payment_status === "expired"
        return { hasDisableBtn, hasPaid, hasExpired, hasCancelled,payment }

    }
    const sendPaymentLink = async (component: string) => {
        console.log('component:', component === getOrderList[0].notes.order_by);
        const findComponent = getOrderList.find((order: any) => order.notes.order_by === component);
        try {
            await sendAgainPaymentLink(findComponent.provider_link_id)
        } catch (e: any) {
            ShowAlert("warning", e?.response?.data?.message || "Some Technical Issue in Sending PaymentLink")
            console.log('e:', e)
        }
    }
    // useEffect(() => {
    //     connectSse();
    //     //eslint-disable-next-line
    // }, [paymentBtnStatus_Ambulance, paymentBtnStatus_Doctor])
    return {
        paymentBtnStatus_Ambulance,
        paymentBtnStatus_Doctor,
        sendPaymentLink
    };
}