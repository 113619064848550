import { UseGetStoreData } from "../getStoreData/UseGetStoreData";

export const useEmergencyContact = () => {
  const { getTicketData } = UseGetStoreData();

  const getEmergencyContact = (emList: any) => {
    const MessageToList = emList?.map((el: any) => {
      return el.mobile || el.whatsappNumber;
    });
    return MessageToList?.length ? MessageToList : [];
  };

  const filterValidNumber = (waNum: any) => {
    return waNum.filter(
      (validNum: any) =>
        validNum !== undefined && validNum !== null && validNum !== ""
    );
  };

  let emergencyContact = getEmergencyContact(
    getTicketData?.patient?.emergencyContacts
  );

  const getTicketRaisedByNum = getTicketData?.ticketRaisedBy?.mobile;
  const getPatientNum = getTicketData?.patient?.mobile;
  //pocNum//
  //companyNum//for premise--flow
  
  emergencyContact.push(getTicketRaisedByNum, getPatientNum);

  const validContacts = filterValidNumber(emergencyContact);

  return {validContacts};
};
