import { connectCall } from "../../pages/actions/actions";
import { ShowAlert } from "../../utils/showAlert";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData"

export const useAgentManualCall=()=>{
    const {getTicketData}=UseGetStoreData();
    const handleConnectCall=async(phNum:string)=>{
       if(!getTicketData?.agent?.email || !phNum){
        ShowAlert("warning","Agent Email or Customer Mobile Number Notfound")
       }else{
           const payload = {
            agentId:getTicketData.agent.email,
            customerNumber:phNum,
          };
          await connectCall(payload,getTicketData._id);
       }
    };
    return{
        handleConnectCall
    }
}