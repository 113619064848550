import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Button } from "../../atoms/button/Button";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { AmbulanceBooking } from "../ambulanceBooking/AmbulanceBooking";
import { DoctorConsult } from "../doctorConsult/DoctorConsult";
import style from "../../../pages/ticketHandlingPart2/ticketHandlingPart2.module.scss";
interface LogisticsAmbulanceDoctorTabProps {
  getTicketStatus: string;
  handleCloseTicket: any;
  getPatientPickupAddress: any;
  getPatientDropLocation: any;
  pickupLocationLabel: string;
}
export const LogisticsAmbulanceDoctorTab: React.FC<
  LogisticsAmbulanceDoctorTabProps
> = ({
  getTicketStatus,
  handleCloseTicket,
  getPatientPickupAddress,
  getPatientDropLocation,
  pickupLocationLabel,
}) => {
  return (
    <>
      <BackgroundWrapper className={style.background}>
        <Container className={style.step}>
          <Button text="Completed :2/3" className={style.step_button} />
        </Container>
        <Container className={style.wrapper}>
          <Container className={style.doctor_component}>
            <Text className={style.heading} text="Doctor Consult" />
            <DoctorConsult />
          </Container>
          <Container className={style.ambulance_component}>
            <Text className={style.heading} text="Ambulance" />
            <AmbulanceBooking
             label={pickupLocationLabel}
              pickupLocation={getPatientPickupAddress}
              dropLocation={getPatientDropLocation}
            />
          </Container>
        </Container>
      </BackgroundWrapper>
      <Container>
        <Button
          disable={getTicketStatus === "completed" ? true : false}
          className={`${
            getTicketStatus !== "completed" ? style.button : style.is_disabled
          }`}
          text="Emergency Resolved"
          onClick={handleCloseTicket}
        />
      </Container>

    </>
  );
};
