import { ToastContainer } from "react-toastify";
import { Navbar } from "./components/organisms/navbar/Navbar";
import { MainRoutes } from "./mainRoutes/MainRoutes";
import "react-toastify/dist/ReactToastify.css";
import logger from './logger'; 
import { ModalProvider } from "./components/organisms/modalProvider/ModalProvider";

// Override global console methods
if (process.env.REACT_APP_NODE_ENV === 'production') {
  // alert("sdds")
  console.log = logger.log;
  console.error = logger.error;
  console.warn = logger.warn;
}

function App() {

  return (
    <ModalProvider>
    <div >
      <ToastContainer autoClose={3000} />
      <Navbar />
      <MainRoutes />
      
    </div>
    </ModalProvider>
  );
}

export default App;