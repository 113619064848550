import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { AddressCard } from "../../molecules/addressCard/AddressCard";
import style from "./pickupLocation.module.scss";
import { Icon } from "../../icon/Icon";
import { usePickupLocation } from "../../../hooks/usePickupLocation/UsePickupLocation";
import { AddressModal } from "../../molecules/addressModal/AddressModal";
interface PickupLocationProps {
  selectedAddress: any;
  setSelectedAddress: any;
  handleAddressSelection: any;
}

export const PickupLocation: React.FC<PickupLocationProps> = ({
  selectedAddress,
  setSelectedAddress,
  handleAddressSelection,
}) => {
  const {  
    formattedAddress,
    isOpen,
    handleCancel,
    address1,
    address2,
    setAddress2,
    setAddress1,
    landmark,
    setLandmark,
    ambulanceAvailable,
    setAmbulanceAvailable,
    liftAvailable,
    setLiftAvailable,
    autocompleteSuggestions,
    handleInputChange,
    handleSuggestionClick,
    saveAddress,
    handleOpenModal,
    CurrentLocation,  

  } = usePickupLocation({handleAddressSelection});

  console.log('formattedAddress:', formattedAddress,CurrentLocation)
  return (
    <>
      <BackgroundWrapper className={style.background}>
        <Container>
          <Text text="Select Pickup Location" className={style.title} />
          <Text text={CurrentLocation?.length?"Our system shows you are at <Location>, just wanted to confirm it is the medical emergency location?":"“Our system couldn't detect your current location. From your saved locations, I can see the following options. Please help me choose the correct one. Alternatively, you can add a new location also”"} className={`${style.question} ${style.space}`} />
          <Text text="Live Location" className={`${style.address_title} ${style.space}`} />
        </Container>
        <Container className={style.address_wrapper}>
          <div>
            <Container className={style.addressCard}>
              {CurrentLocation.map((address:any,index:number)=> (
                <>
                  <AddressCard
                    key={index+1}
                    leftSideIcon="/images/home.png"
                    rightSideBadgeText="LiveLocation"
                    address={address}
                    handleAddressSelection={handleAddressSelection}
                    selectedAddress={selectedAddress}
                  />
                </>
              ))}
            </Container>
            <Container className={style.card}>
              <div onClick={handleOpenModal} className={style.icon}>
                <Icon  className={style.icon_prop} src="/images/plus.png" alt="Plus Icon" />
                <Text className={style.btn} text="Add New Location" />
              </div>
            </Container>
          </div>
          <Container>
            <Text text="Saved Address" className={style.address_title} />
            <Container className={style.addressCard}>
              {formattedAddress&&formattedAddress.length ? (
                formattedAddress.map(
                  (address: any, index: number) => {
                    return (
                      <>
                        <AddressCard
                          key={index+1}
                          leftSideIcon="/images/home.png"
                          rightSideBadgeText="Saved"
                          address={address}
                          handleAddressSelection={handleAddressSelection}
                          selectedAddress={selectedAddress}
                        />
                      </>
                    );
                  }
                )
              ) : (
                <>No Address Found</>
              )}
            </Container>
          </Container>
          {isOpen&&<AddressModal
            isOpen={isOpen}
            onClose={handleCancel}
            address1={address1}
            setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            landmark={landmark}
            setLandmark={setLandmark}
            ambulanceAvailable={ambulanceAvailable}
            setAmbulanceAvailable={setAmbulanceAvailable}
            liftAvailable={liftAvailable}
            setLiftAvailable={setLiftAvailable}
            autocompleteSuggestions={autocompleteSuggestions}
            handleInputChange={handleInputChange}
            handleSuggestionClick={handleSuggestionClick}
            saveAddress={saveAddress}
          />}
        </Container>
      </BackgroundWrapper>
    </>
  );
};
