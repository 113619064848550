import { useEffect, useState } from "react";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useModal } from "../../components/organisms/modalProvider/ModalProvider";
import { ShowAlert } from "../../utils/showAlert";
import { sendPaymentViaLink, updateTicket } from "../../pages/actions/actions";
import { useSseTrigger } from "../UseSseTrigger/UseSseTrigger";
import { currentDateTime } from "../../utils/helper";
import { useDispatch } from "react-redux";



export const usePaymentLink = ({ component }: any) => {
    const dispatch=useDispatch()
  
    const [error, setError] = useState('');
    const [amount, setAmount] = useState(null);
    const [number, setNumber] = useState(null)
    const [email, setEmail] = useState(null)
    const { getTicketData,getTicketUpdateData } = UseGetStoreData();
    const { connectSse } = useSseTrigger();
    console.log('getTicketData:', getTicketData,getTicketUpdateData);
    const [selectedDetails, setSelectedDetails] = useState<any>([]);
    // const { paymentBtnStatus_Ambulance,paymentBtnStatus_Doctor } = usePaymentStatus()
    const [isLoading, setIsLoading] = useState(false);
    const { hideModal } = useModal();
    function buildPayload() {
        let benList = [];
        if (getTicketData?.patient) {
            benList.push({
                id: "patient",
                name: getTicketData?.patient?.fullName,
                mobile: getTicketData?.patient?.mobile,
                email: getTicketData?.patient?.email
            });
            if (getTicketData?.patient?.emergencyContacts) {
                getTicketData?.patient?.emergencyContacts.forEach((item: any, index: number) => {
                    benList.push({
                        id: `em-${index + 1}`,
                        name: item.fullName,
                        mobile: item.mobile
                    })
                })
            };
        } if (getTicketData?.poc) {
            benList.push({
                id: "poc",
                name: getTicketData?.poc?.fullName,
                mobile: getTicketData?.poc?.mobile
            })
        };
        return benList;
    };
    const benList = buildPayload();
    const handleCatchAmount = (e: any) => {
        let value = e.target.value;
        let formateOnlyDigits = value.replace(/\D/g, '');
        // console.log('formateOnlyDigits:', formateOnlyDigits);
        if (formateOnlyDigits && formateOnlyDigits <= 0) {
            ShowAlert("warning", "Please enter valid amount");
        }
        setAmount(formateOnlyDigits);
    };
    const handleCollectMobileNumber = (e: any) => {
        let value = e.target.value;
        let formateOnlyDigits = value.replace(/\D/g, '');
        // console.log('formateOnlyDigits:', formateOnlyDigits);
        if (formateOnlyDigits && formateOnlyDigits <= 0) {
            ShowAlert("warning", "Please enter valid amount");
        }
        setNumber(formateOnlyDigits);
    };
    const handleCollectEmail = (e: any) => {
        const value = e.target.value;
        setEmail(value);
        if (validateEmail(value)) {
            setError('');
        } else {
            setError('Invalid email format');
        };
    };
    const validateEmail = (email: any) => {
        // Simple regex for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    ///
    const buildPayloadForPaymentLink = async () => {
        setIsLoading(true);
        // console.log('getTicketData:', getTicketData);
        const min = 1000000; // Starting range (e.g., 1 million)
        const max = 20000000;
        let basePayload: any = {
            amount: amount ? +amount : 1,
            payment_status: "created",
            currency: "INR",
            notes: {
                order_by: component
            },
            order_id: Math.floor(Math.random() * (max - min + 1)) + min.toString(),
            description: component,
            callback_url: "https://pococare.com",//in backend i changed these url based on the env//
            callback_method: "get",
            receipt: component,
            ticket_id: getTicketData?._id?.toString(),
            beneficiary: {
            }
        }
        if (selectedDetails.length) {
            selectedDetails.forEach((item: any, index: number) => {
                if (item.email) {
                    basePayload.beneficiary = {
                        name: item.name,
                        mobile: item.mobile,
                        email: item.email
                    };
                } else {
                    basePayload.beneficiary = {
                        name: item.name,
                        mobile: item.mobile
                    };
                }
            })
        } else {
            if (email) {
                basePayload.beneficiary = {
                    name: getTicketData?.patient?.fullName,
                    mobile: number,
                    email: email
                };
            } else {
                basePayload.beneficiary = {
                    name: getTicketData?.patient?.fullName,
                    mobile: number
                };
            }
        }
        try {

            await sendPaymentViaLink(basePayload);
            const payload = {
                notes: [...getTicketData?.notes, {
                    date: currentDateTime(),
                    message: `PaymentLink Send for ${component}`
                }]
            }
            console.log('payload:---for paymentSend', payload);
            await updateTicket(getTicketData._id, payload, dispatch);           
            connectSse();
            hideModal();
            setEmail(null);
            setNumber(null);
            setAmount(null);
            setIsLoading(false);
            setSelectedDetails([]);
        } catch (e: any) {
            setEmail(null);
            setNumber(null);
            setAmount(null);
            setSelectedDetails([]);
            console.log('error:', e);
            setIsLoading(false);
            return ShowAlert("warning", e?.response?.data?.message[0] || e?.message || "some error in sending payment link");
        };
    };
    const handleSendPaymentLink = async () => {
        if (!amount) {
            return ShowAlert("warning", "Please enter valid amount");
        } else if (!selectedDetails.length && !number && !email) {
            return ShowAlert("warning", "Please enter valid email or mobile or select beneficiary");
        } else if (selectedDetails.length && (number || email)) {
            setEmail(null)
            setNumber(null)
            return ShowAlert("warning", "either (email , mobile) or select beneficiary");

        } else if (isLoading) {
            return ShowAlert("warning", "Pease wait Progress..");
        }
       await buildPayloadForPaymentLink();
    };
    const handleSelectChange = (value: any) => {
        const selectedItems = benList.filter((item) => value.includes(item.id));
        console.log('selectedItems:', selectedItems);
        if (selectedItems.length > 0) {
            setSelectedDetails(selectedItems);
        }else{
            setSelectedDetails([])
        }
    };
    useEffect(() => {
        //when ever closing the payment modal it will clear the all the filled values////
        setSelectedDetails([]);
        setEmail(null);
        setNumber(null);
        setAmount(null);

    }, [hideModal])
    return {
        amount, error, number, handleSendPaymentLink, email, handleCollectMobileNumber, handleCatchAmount, handleCollectEmail, handleSelectChange, benList, isLoading, selectedDetails
    }
}