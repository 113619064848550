import { useNavigate } from "react-router-dom";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useAmbulanceBooking } from "../useAmbulanceBooking/UseAmbulanceBooking";
import { useTabHandling } from "../useTabHandling/UseTabHandling";

export const useTicketHandlingPart2 = () => {
  const { handleTabSwitch, showOperationTab } = useTabHandling();
  const { getTicketData, getTicketStatus } = UseGetStoreData();
  const { handleTicketUpdate } = useAmbulanceBooking();
  // console.log('showOperationTab:', showOperationTab);
  const navigate = useNavigate();
  const handleCloseTicket = async () => {
    await handleTicketUpdate("resolve");
    navigate("/");
  };
  const handleOperationTask = () => {
    navigate("/operations");
  };
  const getPatientPickupAddress =
    getTicketData?.patientLocation?.address || "N/A";
  const getPatientDropLocation = getTicketData?.hospitalInfo|| "N/A";
  const pickupLocationLabel=getTicketData?.patientLocation?.label||"N/A"
  return {
    handleCloseTicket,
    handleOperationTask,
    getTicketData,
    getPatientPickupAddress,
    getPatientDropLocation,
    getTicketStatus,
    handleTabSwitch,
    showOperationTab,
    pickupLocationLabel
  };
};
