import { useState } from "react";
import { useOperation } from "../useOperation/UseOperation";
import { updateTicket } from "../../pages/actions/actions";
import { useNavigate, useParams } from "react-router-dom";
import { ShowAlert } from "../../utils/showAlert";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { currentDateTime } from "../../utils/helper";

export const useSelectOPtion = () => {
  const { getTicketData } = UseGetStoreData();
  const navigate = useNavigate();
  const { data } = useOperation();
  const [isBtnSelected, setIsBtnSelected] = useState({
    general: false,
    demo: false,
  });
  const { id } = useParams();
  const [optionSelected, setOptionSelected] = useState({
    insurance: false,
    hospital: false,
  });
  const handleGeneralEnquiry = () => {
    setIsBtnSelected((prev: any) => ({
      ...prev,
      general: !prev.general,
      demo: false,
    }));
    setOptionSelected((prev: any) => ({
      ...prev,
      hospital: false,
      insurance: false,
    }));
  };
  const handleDemo = async () => {
    setIsBtnSelected((prev: any) => ({
      ...prev,
      demo: !prev.demo,
      general: false,
    }));

    const payload = {
      ticketStatus: "TestDemo",
      notes: [
        ...getTicketData?.notes,
        {
          date: currentDateTime(),
          message: "Ticket Resolved-test_demo",
        },
      ],
    };
    if (id) {
      await updateTicket(id, payload);
      ShowAlert("success", "Ticket Updated Successfully");
      navigate("/");
    } else {
      ShowAlert("warning", "Invalid Ticket");
    }
  };

  const handleSelectInsurance = () => {
    setOptionSelected((prev: any) => ({
      ...prev,
      insurance: !prev.insurance,
      hospital: false,
    }));
  };
  const handleSelectHospitals = () => {
    setOptionSelected((prev: any) => ({
      ...prev,
      hospital: !prev.hospital,
      insurance: false,
    }));
  };

  const handleResolveTicket = async () => {
    const payload = {
      ticketStatus: "GeneralEnquiry",
      notes: [
        ...getTicketData?.notes,
        {
          date: currentDateTime(),
          message: "Ticket Resolved-general enquiry",
        },
      ],
    };
    if (id) {
      await updateTicket(id, payload);
      ShowAlert("success", "Ticket Updated Successfully");
      navigate("/");
    } else {
      ShowAlert("warning", "Invalid Ticket");
    }
  };

  return {
    handleSelectHospitals,
    handleSelectInsurance,
    handleDemo,
    handleGeneralEnquiry,
    optionSelected,
    isBtnSelected,
    data,
    handleResolveTicket,
  };
};
