
import { useDispatch } from "react-redux";
import { ShowAlert } from "../../utils/showAlert";
import { useNavigate, useParams } from "react-router-dom";
import { UseFetchBenList } from "../useFetchBenList/UseFetchBenList";
import {  ticketUpdateClear } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { ticketUpdateById } from "../../http";
import { ticketDataSuccess } from "../../redux/ticketSlice/ticketSlice";
import { useGetTicketData } from "../usegetTicketData/UseGetTicketData";
import { currentDateTime } from "../../utils/helper";


export const useFetchTicket = () => {
  // const isTicketDataPresent=useSelector((state:any)=>state?.ticket);
  const { getTicketData: isTicketDataPresent, getTicketUpdateData } = UseGetStoreData();
  // console.log('getTicketData:', isTicketDataPresent,getTicketUpdateData);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
 

  const {shouldFetch,data,isLoading,isError}=useGetTicketData({id});

  const handleSaveAssessment = async () => {
    const { currentMedicalStatusResponse, patient,poc } = getTicketUpdateData;
    if (!currentMedicalStatusResponse?.isItEmergency) {
      ShowAlert("warning","Please Enter Emergency Details");
      return;
    }
    if (
      !currentMedicalStatusResponse?.isAmbulanceRequired ||
      !currentMedicalStatusResponse?.isDoctorConsultationRequired ||
      !currentMedicalStatusResponse?.isPatientConscious
    ) {
      ShowAlert("warning","Please Enter Medical Details");
      return;
    }
    if (!currentMedicalStatusResponse?.currentCondition?.length) {
      ShowAlert("warning","Please Enter Patient Current Condition");
      return;
    }
    if(!poc?.fullName || !poc?.mobile ||poc?.mobile?.length!==10){
      ShowAlert("warning","Please Select Poc or Enter Poc Details");
      return;
    }
    if (!patient) {
      ShowAlert("warning","Please Select Patient");
      return;
    }
    const TicketUpdateTracking = {
      notes: [...isTicketDataPresent?.notes, {
        date: currentDateTime(),
        message: "Assessment Details Updated"
      }]
    };
    const payload = {
      ...getTicketUpdateData,
      ...TicketUpdateTracking,
      ticketStatus: "Work in progress",
    };
    delete payload.ticketId;
    if (id) {
     await updateTicket(id, payload);
  
      ShowAlert("success", "step1 updated");
    }
    //if ticket updated successfully we need to jump to the logistics page//
    navigate(`/logistics`);
    //else need to throw technical issue error
  };

   const updateTicket = async (id: string, ticketPayload: any) => {
    try {
      if (id) {
        const response = await ticketUpdateById(id, ticketPayload);
        if (response) {
         dispatch(ticketDataSuccess(response.data));
         dispatch(ticketUpdateClear());
          // setShowModal(true)
          // sendWhatsAppMessageToEm(sendWhatsappMessagePayload);
        }
      }
    } catch (e) {
      return alert("some Technical Issue");
    }
  };
 //eslint-disable-next-line  
  const {    
  } = UseFetchBenList({
    ticketData: shouldFetch ? data : isTicketDataPresent,
  });

 
    console.log('benList:', shouldFetch,data,isTicketDataPresent);
  return {
    data: shouldFetch ? data : isTicketDataPresent,
    isLoading,
    isError,
    handleSaveAssessment,
    getTicketUpdateData
  };
};
