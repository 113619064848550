import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";

import userReducer from "../userSlice/userSlice"
import ticketReducer from "../ticketSlice/ticketSlice"
import ticketUpdateReducer from "../ticketUpdateSlice/ticketUpdateSlice"
import beneficiaryReducer from "../beneficiarySlice/beneficiarySlice"
import { persistStore } from "redux-persist";
const persistConfig = {
    key: "root",
    storage,
};

const reducers = combineReducers({   
    user:userReducer,
    ticket:ticketReducer,
    tickUpdate:ticketUpdateReducer,
    beneficiary:beneficiaryReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            customSerializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});
const persister = persistStore(store);
export { store, persister };