import { UseGetStoreData } from "../getStoreData/UseGetStoreData"
const useTrackAgentNotes = () => {
    const {getTicketData}=UseGetStoreData();   
    const getTrackStatus=()=>{
        const status=getTicketData&&getTicketData?.notes?.map((note)=>
            note.message
        );
        console.log('status:', status);
        
        let newStep = [0, 0, 0, 0];
        if(status.includes("Ambulance dispatched")){
            newStep[0] = 1; 
        }
        if(status.includes("patientPicked")){
            newStep[1] = 1; 
        }
        if(status.includes("Ambulance arrived pickup location")){
            newStep[2] = 1; 
        }
        if(status.includes("PatientDropped")){
            newStep[3] = 1; 
        }
        return newStep;

    };    
 return {getTrackStatus}
}

export default useTrackAgentNotes