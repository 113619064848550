import { useEffect, useState } from "react";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import {
  autoCompletionResult,
  findClosestAddress,
  getFullAddress,
  getGeoCode,
} from "../../pages/actions/actions";
import { generateUniqueID } from "../../utils/helper";

export const usePickupLocation = ({ handleAddressSelection }: any) => {
  const { getTicketData, getBenList,getTicketUpdateData } = UseGetStoreData();
  const detectedLocation = getTicketData?.detectedLocation;
  console.log('detectedLocation:', detectedLocation);
  const patient = getTicketData?.patient;
  let addressData: any = [];
  detectedLocation && addressData.push({...detectedLocation,label:'Live Location'});
  const [isOpen, setIsOpen] = useState(false);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [ambulanceAvailable, setAmbulanceAvailable] = useState(false);
  const [liftAvailable, setLiftAvailable] = useState(false);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
  const [formattedAddress,setFormattedAddress]=useState<any[]>()
  // const [selectedAddress, setSelectedAddress] = useState<any>(null);

  ////address__collection//
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState<Number>();
  const [country, setCountry] = useState<any>("");
  // console.log('currentAddress1Lat:', currentAddress1Lat);
  const [latitude, setLatitude] = useState("");
  //eslint-disable-next-line
  // console.log('currentAddress1Lon:', currentAddress1Lon);
  const [longitude, setLongitude] = useState("");
  //eslint-disable-next-line
  const [addressLabel, setAddressLabel] = useState("");
  const [CurrentLocation, setCurrentLocation] = useState<any>(addressData); 

  async function getPatientAddress(benList: any) {
    //this or condition will work mainly on the generalEnquiry that time ///
    // console.log('getTicketUpdateData:################################################', patient,getTicketUpdateData)
    // console.log('benList:#################', benList,patient?.id||getTicketUpdateData?.patient?.id)
    const idToFind = patient?.id || getTicketUpdateData?.patient?.id;
     const res=benList.find((ben: any) => ben._id === idToFind);  
    //  console.log(res._id,"#################")
     return res   
  };
  const checkGeoCodePresentInAddress = async (patientAddress: any[]) => {
    let formattedAddress = [];
    for (const address of patientAddress) {
      console.log('address:---1', address);
      if (
        address?.location?.latitude !== undefined &&
        address?.location?.longitude !== undefined &&(address?.location?.longitude!==0 &&   address?.location?.latitude !== 0 )
      ) {
        
        formattedAddress.push(address); //proper address
      } else if (
        Array.isArray(address.location) &&
        address?.location[0]?.latitude !== undefined &&
        address?.location[0]?.longitude !== undefined &&address?.location[0]?.longitude!==null &&   address?.location[0]?.latitude !== null &&(
          address?.location[0]?.longitude!==0 &&   address?.location[0]?.latitude !== 0 
        )
      ) {
        const updatedAddress = {
          ...address,
          location: {
            latitude: Number(address?.location[0]?.latitude),
            longitude: Number(address?.location[0]?.longitude),
          },
        };
        formattedAddress.push(updatedAddress); //some old data in a
      } else if (
        address.addressLine1 &&
        address.city &&
        address.state &&
        address.pinCode
      ) {
      
        const addressText = `${address.addressLine1}${address.city} ${address.state} ${address.pinCode}`;
        const response = await getGeoCode(addressText);
        if (response) {
          const updatedAddress = {
            ...address,
            location: {
              latitude: Number(response?.lat),
              longitude: Number(response?.lng),
            },
          };
          formattedAddress.push(updatedAddress); //some old data in a
        }else{
            const updatedAddress = {
                ...address,
                label: "googleApi error",
              };
              formattedAddress.push(updatedAddress); //if suppose google address gives error
        }
      } else {
        // alert("check-else")
        const updatedAddress = {
          ...address,
          label: "Invalid Address",
        };
        formattedAddress.push(updatedAddress);
      }
    }
    return formattedAddress;
  };
  async function checkAddressValidation(benList: any) {

    console.log('benList:', benList);
    const patientSavedAddress = await getPatientAddress(benList);
    // console.log('patientSavedAddress:###########################################', patientSavedAddress);
    const patientFormattedAddress=await checkGeoCodePresentInAddress(patientSavedAddress?.address||[]);
    // setFormattedAddress(patientFormattedAddress);
    return patientFormattedAddress
  } 
  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };
  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setAddress1(inputValue);
    //logic to fetch autocomplete suggestions
    if (inputValue.length % 3 === 0) {
      // setShowDropdown(true);
      const predictions = await autoCompletionResult(inputValue);
      setAutocompleteSuggestions(predictions || []);
    }
  };
  const handleSuggestionClick = async (suggestion: any) => {
    if (suggestion) {
      setAddress1(suggestion?.description);
      const response = await getFullAddress(suggestion?.place_id);
      const { city, state, country, pincode, latitude, longitude } =
        response.data;
      setCity(city);
      setCountry(country);
      setPincode(Number(pincode));
      setState(state);
      setLatitude(latitude);
      setLongitude(longitude);
    }

    setAutocompleteSuggestions([]);
  };
  const saveAddress = () => {
    // Add logic to save the address
    let addressPayload = {
      addressLine1: address1,
      addressLine2: address2,
      isReachableByAmbulance: ambulanceAvailable,
      IsLiftAvailable: liftAvailable,
      city: city,
      landmark: landmark || "",
      pinCode: Number(pincode),
      state: state,
      label: addressLabel,
      country: country,
      location: { latitude: Number(latitude), longitude: Number(longitude) },
      _id: generateUniqueID(),
    };
    if (
      typeof ambulanceAvailable !== "boolean" ||
      typeof liftAvailable !== "boolean" ||
      !address1
    ) {
      return alert(
        "Please Select the Ambulance & lift field || enter the AddressLine 1"
      );
    }
    setCurrentLocation([...addressData, addressPayload]);
    handleAddressSelection(addressPayload);
    setIsOpen(false);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  useEffect(()=>{
    const fetchPatientSavedAddress = async () => {
        const formattedAd = await checkAddressValidation(getBenList);
        // console.log('formattedAddresses:------1', formattedAd); 
        if(detectedLocation){
          const getCurrentLocation = await findClosestAddress(
            detectedLocation?.geoLocation?.coordinates[0],
            detectedLocation?.geoLocation?.coordinates[1],
            formattedAd
          );
        
          if(getCurrentLocation){
            let savedAddress:any=[];
            let currenLocation:any=[];
            formattedAd.forEach((address: any) => {
              if (address?.addressLine1 === getCurrentLocation?.addressLine1 && address?.addressLine2 === getCurrentLocation?.addressLine2 && address?.city === getCurrentLocation?.city && address?.state === getCurrentLocation?.state && address?.pinCode === getCurrentLocation?.pinCode)  {
               currenLocation.push(address)
              }else{
                savedAddress.push(address)
              }
            });
            setCurrentLocation(currenLocation)
            setFormattedAddress(formattedAd);
          }
          setFormattedAddress(formattedAd);       
        } else{
          setFormattedAddress(formattedAd);
        }     
      };
  
      fetchPatientSavedAddress();
       //eslint-disable-next-line
  },[getBenList,getTicketUpdateData])
  return {
    CurrentLocation,
    formattedAddress,
    handleInputChange,
    handleSuggestionClick,
    saveAddress,
    handleCancel,
    isOpen,
    address1,
    address2,
    setAddress2,
    landmark,
    setLandmark,
    ambulanceAvailable,
    setAmbulanceAvailable,
    liftAvailable,
    setLiftAvailable,
    autocompleteSuggestions,
    setAddress1,
    handleOpenModal,
  };
};
