import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Text } from "../../atoms/text/Text";
import style from "./insurance.module.scss";
import { DynamicCard } from "../dynamicCard/DynamicCard";
import { Container } from "../../atoms/container/Container";
import { ShareProfileButton } from "../shareProfileButton/ShareProfileButton";
import { useInsuranceCard } from "../../../hooks/useInsuranceCard/UseInsuranceCard";
interface InsuranceCardProps {
  data: any;
}
export const InsuranceCard: React.FC<InsuranceCardProps> = ({ data }: any) => {
const  {filteredData,handleShareInsuranceDet}=useInsuranceCard({data});
  return (
    <>
      <BackgroundWrapper className={style.background}>
        <Container className={style.container}>
          <Text className={style.heading} text="Insurance" />
          {filteredData.length ? (
            <div>
              {filteredData.map((data: any,index:number) => (
                <DynamicCard
                  className={style.fontStyles}
                  key={index}
                  data={data}
                  excludeFields={[]}
                />
              ))}
              <Container className={style.btn_wrap}>
                <ShareProfileButton
                  onClick={handleShareInsuranceDet}
                  btnText="Share a Copy"
                />
              </Container>
            </div>
          ) : (
            <>No data Found</>
          )}
        </Container>
      </BackgroundWrapper>
    </>
  );
};
